import { isArray, camelCase, isObject, transform } from "lodash";

export const snakeCaseToCamelCase = (data) => {
  return transform(data, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    acc[camelKey] = isObject(value) ? snakeCaseToCamelCase(value) : value;
  });
};

const supportedStoreProperties = [
  "background_image",
  "show_language_switcher",
  "logo_image",
  "logo_image_variant1",
  "favicon",
  "name",
  "locale",
  "spinner",
  "show_period_picker",
];

export const setupAppConfiguration = (data) => {
  const dataKeys = Object.keys(data);

  let store = {};

  /*
    Group properties within a group which falls into below categories
    1. Parent objects which decide if it should be grouped by theme or store
    2. Vuetify's default color objects (primary,secondary..) which expects a grouped structure {base:"hexcode",ligthen1:"hexcode",darken-1:"hexcode"}
  */
  dataKeys.reduce((result, currKey) => {
    if (result[currKey] === undefined) result[currKey] = {};
    if (supportedStoreProperties.includes(currKey)) {
      /*
          Setup app store properties 
      */
      store[currKey] = data[currKey];
    }
    return result;
  }, {});

  store = snakeCaseToCamelCase(store);
  return { store };
};
